<template>
  <div>
    <h1>แผนที่การเดินตรวจ</h1>
    <br />
    <div class="innfobox">
      <div class="card" style="width: 100%" v-if="roundInfoData != null">
        <div class="card">
          <div class="card-horizontal">
            <div class="img-square-wrapper">
              <img
                v-img
                style="width: 240px"
                :src="roundInfoData.member_verify_photo"
                class="img-fluid card-img-top"
                :key="'x'"
              />
            </div>
            <div class="card-body card-info">
              <h4 class="card-title">
                <b>{{ roundInfoData.member_name }} <span v-if="roundInfoData.is_cancel" style="color:#ae1a1a;">(ยกเลิกการตรวจ)</span></b>
              </h4>
              <br />
              <p class="card-text">
                <b>เวลา</b> {{ roundInfoData.create_date | formatDate }}
              </p>
              <p class="card-text"><b>รอบ</b> {{ roundInfoData.round }}</p>
              <p class="card-text">
                <b>เวลาเริ่มตรวจ</b>
                {{ roundInfoData.walk_start_datetime | formatDate }}
              </p>
              <p class="card-text">
                <b>เวลาเสร็จสิ้นการตรวจ</b>
                {{ roundInfoData.walk_end_datetime | formatDate }}
              </p>
            </div>
          </div>
          <div class="card-footer"></div>
        </div>
      </div>
    </div>
    <br />
    <gmap-map
      ref="mapRef"
      :center="center"
      :zoom="12"
      style="width: 100%; height: 400px"
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="toggleInfoWindow(m, index)"
        :icon="getMarker(m)"
      ></gmap-marker>
      <!--
         <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
         @click="toggleInfoWindow(m,index)"
         :label="{'text': ''+m.order, 'color': 'white'}"
         :icon="getMarker(m)"></gmap-marker> -->
      <gmap-info-window
        :options="infoOptions"
        :position="infoWindowPos"
        :opened="infoWinOpen"
        @closeclick="infoWinOpen = false"
      >
        <div v-html="infoContent"></div>
      </gmap-info-window>
    </gmap-map>
    <vs-card style="background-color: #f1eded; margin-top: 10px; padding: 0px">
      <vs-table stripe :data="markers">
        <template slot="thead">
          <vs-th class="order-col">ลำดับ</vs-th>
          <vs-th class="name-col">จุดตรวจ</vs-th>
          <vs-th class="name-col">เวลาตรวจ</vs-th>
          <vs-th class="status-col">สถานะ</vs-th>
          <vs-th>รายงาน</vs-th>
          <vs-th>รูปแนบ</vs-th>
        </template>
        <template>
          <vs-tr
            class="map-list-table"
            :key="index"
            v-for="(trr, index) in markers"
            style="cursor: pointer"
            v-on:click.native="toggleInfoWindow(markers[index], index)"
          >
            <vs-td class="order-col">
              {{ markers[index].order }}
            </vs-td>
            <vs-td class="name-col">
              {{ markers[index].name }}
            </vs-td>
            <vs-td class="name-col">
              {{ markers[index].description }}
            </vs-td>
            <vs-td class="status-col">
              {{ markers[index].date_build }}
            </vs-td>
            <vs-td>
              {{ markers[index].desc }}
            </vs-td>
            <vs-td class="img-ul">
              <ul
                v-if="
                  Array.isArray(markers[index].photos) &&
                  markers[index].photos.length > 0
                "
              >
                <li
                  :key="indextr_image"
                  v-for="(image, indextr_image) in markers[index].photos"
                >
                  <img
                    v-img
                    style="width: 40px"
                    :src="image"
                    class="img-fluid"
                    :key="indextr_image"
                  />
                </li>
              </ul>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-card>
  </div>
</template>

<script>
import Vue from "vue";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import VueImg from "v-img";

Vue.use(VueCookies);
Vue.use(VueImg);

Vue.filter("formatDate", function (value) {
  if (value) {
    const myDate = new Date(value.seconds * 1000); // date object
    return moment(String(myDate)).format("DD/MM/YYYY HH:mm:ss");
  }
  return "-";
});

export default {
  name: "GoogleMap",
  data() {
    return {
      checkpointOrderList: [],
      roundInfoData: null,
      round_id: "",
      center: { lat: 15.870032, lng: 100.992538 },
      markers: [],
      currentPlace: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      infoContent: "",
    };
  },

  mounted() {},
  async created() {
    this.round_id = new URL(location.href).searchParams.get("id");
    this.roundInfo();
    await this.getCheckPointOrder();
    await this.listItems();
    // console.log("xxxx", this.round_id);
  },
  methods: {
    replaceText: function (value, withValue) {
      console.log("replaceText value=" + value);

      if (value === null || value === "" || value === undefined) {
        return withValue;
      }
      return value;
    },
    roundInfo() {
      const customerName = $cookies.get("customer");
      const _ducumentPath = `customer/${customerName}/scan/data/transaction_round/`;
      console.log("_ducumentPath", _ducumentPath);
      db.collection("customer")
        .doc(customerName)
        .collection("scan")
        .doc("data")
        .collection("transaction_round")
        .doc(this.round_id)
        .get()
        .then((value) => {
          this.roundInfoData = value.data();
          console.log("this.roundInfoData", this.roundInfoData);
        });
    },

    async getCheckPointOrder() {
      const customerName = $cookies.get("customer");
      const _ducumentPath = `customer/${customerName}/scan/data/checkpoint/`;
      console.log("_ducumentPath", _ducumentPath);
      await db
        .collection(_ducumentPath)
        .orderBy("order", "desc")
        .get()
        .then((document) => {
          document.forEach((doc) => {
            console.log(">>>doc.data()=", doc.data());
            this.checkpointOrderList[this.checkpointOrderList.length] =
              doc.data();
          });
          console.log(">>>checkpointOrderList=", this.checkpointOrderList);
        });
    },
    dbRef() {
      const customerName = $cookies.get("customer");
      const _ducumentPath =
        `customer/${customerName}/scan/data/transaction_round/` +
        this.round_id +
        `/transaction`;
      console.log("_ducumentPath", _ducumentPath);
      return db.collection(_ducumentPath).orderBy("scanDateTime","asc");
    },
    async listItems() {
      await this.dbRef().onSnapshot(async (snapshotChange) => {
        this.markers = [];

        let items = [];
        await snapshotChange.forEach(async (doc) => {
          const data = doc.data();

          // console.log("data", data);

          items[items.length] = {
            name: data.name,
            // order: counter++,
            sort: this.getOrder(data.name),
            description:
              "เวลาตรวจ " + Vue.filter("formatDate")(data.scanDateTime),
            date_build: data.isSkip === true ? "ข้ามจุด" : data.is_ok === true ? "เหตุการณ์ปกติ" : "เหตุการณ์ไม่ปกติ",
            is_ok: data.is_ok,
            photos: data.photos,
            desc: data.desc,
            position: { lat: data.latitude, lng: data.longitude },
            order: 1,
          };
        });

        // items = items.sort(function (a, b) {
        //   return parseInt(b.sort) - parseInt(a.sort);
        // });

        let counter = 1;
        for (let i = 0; i < items.length; i++) {
              items[i].order = counter++;
        }

        this.markers = items;
          console.log("items", items);

        this.$refs.mapRef.$mapPromise.then((map) => {
          const bounds = new google.maps.LatLngBounds();
          for (const m of this.markers) {
            map.panTo(m.position);
            bounds.extend(m.position);
          }
          map.fitBounds(bounds);
        });
      });
    },

    getOrder: function (name) {
      // console.log(">>getOrder=" + name);
      // console.log(
      //   ">>this.checkpointOrderList=" + this.checkpointOrderList.length
      // );

      for (let i = 0; i < this.checkpointOrderList.length; i++) {
        const item = this.checkpointOrderList[i];
        // console.log(">>item.name=" + item.name + ", name=" + name);

        if (item.name === name) return item.order;
      }

      return 0;
    },
    getMarker: function (item) {
      console.log("item=" + item.is_ok);

      let iconName = require("../assets/images/pin/red.svg");

      if (item.is_ok === true) {
        iconName = require("../assets/images/pin/green.svg");
      }

      return {
        url: iconName,
        scaledSize: { width: 36, height: 36 },
      };
    },
    toggleInfoWindow: function (marker, idx) {
      console.log("marker.position=", marker.position);
      this.infoWindowPos = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    getInfoWindowContent: function (marker) {
      return `<div class="card">
  <!--<div class="card-image">
    <figure class="image is-4by3">
      <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image">
    </figure>
  </div>-->
  <div class="card-content">
    <div class="media">
      <div class="media-content">
        <p class="title is-4">${marker.name}</p>
      </div>
    </div>
    <div class="content">
      ${marker.description}
      <br>
      <time datetime="2016-1-1">${marker.date_build}</time>
    </div>
  </div>
</div>`;
    },
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function () {
      //   this.markers = [
      //     {
      //       name: "จุดตรวจที่ 1",
      //       description: "เวลาตรวจ 17:03:22",
      //       date_build: "เหตุการณ์ปกติ",
      //       is_ok: true,
      //       position: {lat: 52.512942, lng: 6.089625},
      //       order: 1
      //     },
      //     {
      //       name: "จุดตรวจที่ 2",
      //       description: "เวลาตรวจ 17:03:22",
      //       date_build: "เหตุการณ์ปกติ",
      //       is_ok: true,
      //       position: {lat: 52.511950, lng: 6.091056},
      //       order: 8
      //     },
      //     {
      //       name: "จุดตรวจที่ 3",
      //       description: "เวลาตรวจ 17:03:22",
      //       date_build: "เหตุการณ์ไม่ปกติ",
      //       is_ok: false,
      //       position: {lat: 52.511047, lng: 6.091728},
      //       order: 3
      //     },
      // ];
    },
  },
};
</script>

<style>
.card-info {
  padding: 12px;
  margin-left: 10px;
  width: 100%;
  font-size: 18px;
}

.card-horizontal {
  display: flex;
  flex: 1 1 auto;
}

.fullscreen-v-img {
  z-index: 9999999999999 !important;
}

.img-ul ul li {
  position: relative;
  border: 2px solid rgb(143, 141, 141);
  display: inline-block;
  text-align: center;
  margin-left: 6px;
}

.img-ul ul {
  text-align: left;
}
</style>

<style scoped>
.order-col {
  width: 60px;
}

.name-col {
  width: 260px;
}

.status-col {
  width: 160px;
}

.map-list-table td {
  min-height: 100px !important;
}

.vue-map-container {
  border: solid 6px #f1eded;
}
.notNorlmalCase {
  background-color: #c3252a !important;
  color: #fff;
  min-width: 100px;
}

.norlmalCase {
  background-color: #009f22 !important;
  color: #fff;
  min-width: 150px;
}
</style>
